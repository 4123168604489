import './bootstrap';

// Import Alpine.js and its dependencies
import Alpine from 'alpinejs';

// Import your modules
import { initImageModal } from './early-access/modal';
import earlyAccessForm from './early-access/early-access-form';
import placeData from './places';
import imageSlider from './components/imageSlider';

// Make Alpine available globally
window.Alpine = Alpine;

// Register Alpine components before starting
Alpine.data('earlyAccessForm', earlyAccessForm);
Alpine.data('imageSlider', imageSlider);
Alpine.data('placeData', placeData);

// Create stores
Alpine.store('placeData', {
    selectedCategories: [],
    visibleCategories: new Set()
});

Alpine.store('categoryData', {
    visibleCategories: new Set()
});

// Start Alpine after registering components
Alpine.start();

// Initialize other components
document.addEventListener('DOMContentLoaded', () => {
    initImageModal();
});

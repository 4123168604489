export default () => ({
    formStep: 'early-access',
    isSubmitting: false,
    email: '',
    profileSubmitted: false,
    formData: {
        firstName: '',
        email: ''
    },
    errors: {
        firstName: '',
        email: ''
    },
    messageType: '',

    init() {
        // Remove x-cloak when component is initialized
        this.$el.removeAttribute('x-cloak');

        // Define the global handler
        window.submitProfile = (form) => {
            console.log('Global submitProfile called');

            const self = this;  // Store reference to component
            if (self.isSubmitting) return false;

            self.isSubmitting = true;
            const formData = new FormData(form);

            fetch('/mailchimp/update-profile', {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                },
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                console.log('Profile update response:', data);
                if (data.success) {
                    console.log('Setting state...');
                    self.profileSubmitted = true;
                    self.formStep = 'completed';
                    console.log('New state:', self.formStep, self.profileSubmitted);
                }
            })
            .catch(error => {
                console.error('Profile submission error:', error);
            })
            .finally(() => {
                self.isSubmitting = false;
            });

            return false;
        };
    },

    validateEmailFormat(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    },

    validateFirstName() {
        if (!this.formData.firstName.trim()) {
            this.errors.firstName = 'First name is required';
        } else {
            this.errors.firstName = '';
        }
    },

    validateEmail() {
        if (!this.formData.email.trim()) {
            this.errors.email = 'Email is required';
        } else if (!this.validateEmailFormat(this.formData.email)) {
            this.errors.email = 'Please enter a valid email address';
        } else {
            this.errors.email = '';
        }
    },

    async submitEarlyAccess($event) {
        $event.preventDefault();
        if (this.isSubmitting) return;

        // Clear previous errors
        this.errors.email = '';
        this.messageType = '';

        // Do validation before submission
        this.validateFirstName();
        this.validateEmail();

        if (this.errors.firstName || this.errors.email) return;

        this.isSubmitting = true;
        const formData = new FormData($event.target);

        try {
            const response = await fetch('/mailchimp/subscribe', {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                },
                body: formData
            });

            const data = await response.json();
            console.log('Response:', data);

            if (data.success && data.isNewSubscription) {
                // Only proceed to profile form for new subscriptions
                this.email = this.formData.email;
                this.formStep = 'profile';
                this.messageType = 'success';

                // When handling the subscribe response:
                if (data.success && data.profileToken) {
                    // Store the token temporarily
                    const profileToken = data.profileToken;

                    // Add it to your profile form
                    document.querySelector('form#profile-form').innerHTML += `
                        <input type="hidden" name="profile_token" value="${profileToken}">
                    `;
                }
            } else if (data.validationError) {
                // Handle validation errors
                this.errors.email = data.message;
                this.messageType = 'error';
            } else if (data.alreadySubscribed) {
                // Handle already subscribed case
                this.errors.email = data.message;
                this.messageType = 'error';
            } else {
                // Handle other errors
                this.errors.email = data.message || 'An error occurred';
                this.messageType = 'error';
            }
        } catch (error) {
            console.error('Submission error:', error);
            this.errors.email = 'An error occurred. Please try again.';
            this.messageType = 'error';
        } finally {
            this.isSubmitting = false;
        }
    }
});

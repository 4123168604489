class ImageModal {
    constructor() {
        this.modal = document.getElementById('imageModal');
        this.modalImage = document.getElementById('modalImage');
        this.bindEvents();
    }

    open(imageSrc) {
        if (!this.modal || !this.modalImage) return;

        this.modal.classList.remove('hidden');
        this.modal.offsetHeight; // Force reflow
        this.modal.classList.add('opacity-100');

        this.modalImage.src = imageSrc;
        this.modalImage.onload = () => {
            this.modalImage.classList.add('opacity-100');
        }
        document.body.style.overflow = 'hidden';
    }

    close() {
        if (!this.modal || !this.modalImage) return;

        this.modal.classList.remove('opacity-100');
        this.modalImage.classList.remove('opacity-100');

        setTimeout(() => {
            this.modal.classList.add('hidden');
            this.modalImage.src = '';
            document.body.style.overflow = 'auto';
        }, 500);
    }

    bindEvents() {
        // Close modal when clicking outside the image
        this.modal?.addEventListener('click', (e) => {
            if (e.target === this.modal) {
                this.close();
            }
        });

        // Close modal with escape key
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });

        // Add click handlers for all modal images
        document.querySelectorAll('[data-modal-image]').forEach(img => {
            img.addEventListener('click', () => this.open(img.src));
        });
    }
}

let modalInstance;

function initImageModal() {
    modalInstance = new ImageModal();

    // Make functions globally available
    window.openModal = (imageSrc) => modalInstance.open(imageSrc);
    window.closeModal = () => modalInstance.close();
}

export { initImageModal };
